import React from 'react';
import { MainLayout } from '../layouts';
import {
  LegalContainer
} from '../components/pages/shared/LegalStyles';

export default () => (
  <MainLayout>
    <LegalContainer>
        <div>
        <h2 id="developertermsofuse">Developer Terms of Use</h2>
        <p><em>Effective Date: November 24, 2019</em></p>
        <p>Thank you for your interest in developing on AirPage!</p>
        <p>AirPage offers various tools and documentation, such as APIs, API SDKs, code, software, scripts, buttons, widgets, app keys, access tokens and developer pages and documentation (collectively, "Developer Tools") for site developers, application developers, API partners and others who may be integrating AirPage features or functionality into their sites, products or services. These Developer Terms of Use ("Developer Terms”) apply to your access to and use of the Developer Tools. If there is a conflict between these Developer Terms and any additional terms we provide that are applicable to a given set of Developer Tools, the additional terms will control for that conflict.</p>
        <p>Note that any capitalized terms not defined in these Developer Terms have the meanings set forth in our <a href="/terms-of-service">Terms of Service</a>.</p>
        <p>If you have any questions about these Developer Terms, please <a href="/contact">contact us</a>.</p>
        <p><strong>1. &nbsp;&nbsp; &nbsp;Our Licenses To You</strong></p>
        <p><strong>1.1. Our Developer Tools.</strong> Subject to your ongoing compliance with these Developer Terms, as well as our <a href="/terms-of-service">Terms of Service</a>, <a href="/privacy">Privacy Policy</a>, <a href="/acceptable-use-policy">Acceptable Use Policy</a> and <a href="/brand-guidelines">Brand Guidelines</a>, we grant you a limited, non-exclusive, non-transferable and revocable license to use the Developer Tools to develop, test and support your products and services (“Developer Products”). You acknowledge that as between you and AirPage, AirPage retains all worldwide right, title and interest in the Developer Tools, including without limitation all intellectual property rights therein, and these Developer Terms don't grant you any rights in any of our Services or the content accessed through the Services. If you acquire any rights in the Developer Tools, by operation of law or otherwise, you, at no expense to AirPage, hereby assign all such rights to AirPage. Some of the software used in the Developer Tools may be offered under an open source license. The provisions in the open source license and not these Developer Terms govern your use of that software.</p>
        <p><strong>1.2. Our Brand Assets.</strong> You may use the words, phrases, symbols, designs and other distinctive brand features associated with AirPage and our Services (collectively, “Brand Assets”) provided in the Developer Tools, provided that your use complies with these Developer Terms, any documentation provided with the Developer Tools and our <a href="/brand-guidelines">Brand Guidelines</a>. You agree that any goodwill derived from your use of the Brand Assets benefits only AirPage. If you acquire any rights in the Brand Assets, by operation of law or otherwise, you, at no expense to AirPage, hereby &nbsp;assign all such rights to AirPage.</p>
        <p><strong>1.3. Template Source Code.</strong> The Developer Tools include source code that you may use to build websites (“Templates”). Except as otherwise set forth in our <a href="/terms-of-service">Terms of Service</a>, you own any website you develop using the Templates (“Developed Website”), subject to AirPage’s ownership of the Templates. You may only use the Templates to develop websites hosted by AirPage. You may not use the Templates for any purpose that AirPage deems, in its sole discretion, to be competitive or in conflict with AirPage’s interests. If you are a web developer who creates a Developed Website for a client, you may transfer the Developed Website to your client, provided that you comply with these Developer Terms. Any Developed Website that includes any portion of the Templates must include the following notice: “Portions Copyright © 2018 AirPage, Inc. This code is licensed to you pursuant to AirPage’s Developer Terms of Use, available at <a href="/developer-terms">https://airpage.com/developer-terms</a> (the “Developer Terms”). You may only use this code on websites hosted by AirPage, and in compliance with the Developer Terms. TO THE FULLEST EXTENT PERMITTED BY LAW, AIRPAGE PROVIDES ITS CODE TO YOU ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.”</p>
        <p><strong>2.&nbsp;&nbsp; &nbsp;Your Licenses To Us</strong></p>
        <p><strong>2.1. License To Your Developer Product.</strong> By using the Developer Tools, you agree that we may: (a) collect and use information from you and any users of your Developer Product (“Your Users”) as described in our <a href="/privacy">Privacy Policy</a>; (b) use automated methods to analyze your Developer Product; and (c) review or monitor the Developer Tools (including without limitation how our Services are being accessed and used), for purposes like quality control, improving the Services and verifying your compliance with these Developer Terms. You agree that we have no obligation to review or monitor the Developer Tools or our Services. We agree that these Developer Terms do not grant us ownership of your Developer Product.</p>
        <p><strong>2.2. License To Marks.</strong> You grant to AirPage a non-exclusive, non-transferable, perpetual license to use your trademarks, service marks, logos, or trade names (“Marks”) and descriptive materials you publish about, any version or portion of your Developer Product or your use of the Developer Tools, for the purpose of AirPage marketing and promotional activities and to acknowledge or promote your use of the Developer Tools. We agree that these Developer Terms do not grant us ownership of your Marks, and any goodwill derived from use of your Marks benefits only you.</p>
        <p><strong>3.&nbsp;&nbsp; &nbsp;Your Representations And Warranties</strong></p>
        <p>You represent, warrant and covenant that: (a) all information you have provided and will provide to AirPage is true, accurate and complete; (b) you have the full right, power and authority to make, distribute, operate, support and promote your Developer Product, use the Developer Tools and enter into these Developer Terms; and (c) your Developer Product, its contemplated use and your use of the Developer Tools will not violate the intellectual property or other rights of others, or violate any laws.</p>
        <p><strong>4. &nbsp;&nbsp; &nbsp;Your Use Of The Developer Tools</strong></p>
        <p><strong>4.1. Complying With Our Policies.</strong> Any use of the Developer Tools must comply with the AirPage policies and requirements set forth in the Services or other documentation provided by AirPage, which may be modified from time to time.</p>
        <p><strong>4.2. Prohibited Actions.</strong> You, your use of the Developer Tools and your Developer Product may not, and may not attempt to, do any of the following:</p>
        <p><strong>4.2.1.</strong> Violate, or encourage or facilitate the violation of, these Developer Terms, or our <a href="/terms-of-service">Terms of Service</a>, <a href="/privacy">Privacy Policy</a>, <a href="/acceptable-use-policy">Acceptable Use Policy</a> or <a href="/brand-guidelines">Brand Guidelines</a>.</p>
        <p><strong>4.2.2.</strong> Access or attempt to access the Developer Tools by means other than as described in the documentation provided by AirPage. If you are assigned developer credentials (e.g., client IDs or API keys), you must use them with the applicable Developer Tools and cannot misrepresent or mask either your identity or your client’s identity when using the Developer Tools.</p>
        <p><strong>4.2.3.</strong> Mislead, confuse or surprise Your Users. For example, your Developer Product should be transparent with Your Users about what it does, and you should obtain Your Users’ prior affirmative consent to: (a) modify Your Users’ account settings, data or device; (b) store or share Your Users’ content; or (c) attempt to gain any rights to Your Users’ content.</p>
        <p><strong>4.2.4.</strong> Substantially replicate the Services or any other products or services offered by AirPage.</p>
        <p><strong>4.2.5.</strong> Sell, rent, transfer, sublicense or otherwise provide use of any rights granted in these Developer Terms to any third party except as expressly permitted herein.</p>
        <p>If we believe, in our sole discretion, that you have violated or attempted to violate any term, condition or the spirit of any of these rules, your license to and ability to use and access the Developer Tools may be temporarily or permanently revoked, with or without notice.</p>
        <p><strong>4.3. Usage Limits.</strong> AirPage sets and enforces limits on your use of the Developer Tools (e.g., limiting the number of API requests that you may make or the number of users you may serve), in our sole discretion. You may not, and may not attempt to, circumvent such limits.</p>
        <p><strong>4.4. Information About Your Users.</strong> If you collect information from or about Your Users via your Developer Product or the Developer Tools, you must ensure that such information is collected, processed, transmitted, maintained and used in compliance with all applicable laws, industry standard security practices and a privacy policy that you post and make clearly available to Your Users.</p>
        <p><strong>5. &nbsp;&nbsp; &nbsp;Confidentiality</strong></p>
        <p>Confidential Information means non-public business or technical information, including without limitation software, specifications and other non-public information related to the Developer Tools that is marked or otherwise identified as proprietary or confidential at the time of disclosure, or that by its nature would be understood by a reasonable person to be proprietary or confidential. Confidential Information shall include without limitation API keys and client IDs.</p>
        <p>Unless otherwise authorized in writing by AirPage, you agree that you will only use Confidential Information as permitted herein and you will not disclose, sell, license, transfer or otherwise make available any Confidential Information to any person or entity except to your affiliates, employees, clients or agents who need to know it and who have agreed in writing to keep it confidential. You agree that you will protect the Confidential Information from unauthorized use, access or disclosure in the same manner that you would use to protect your own confidential and proprietary information of a similar nature, and in no event with less than a reasonable degree of care. Your confidentiality obligations, as they apply to any clients any of this Confidential Information disclosed prior to termination, will survive termination to any third party without AirPage’s prior written consent.</p>
        <p><strong>6. &nbsp;&nbsp; &nbsp;Termination.</strong></p>
        <p>You may stop using our Developer Tools at any time, with or without cause or notice. AirPage may terminate or suspend the Developer Tools or these Developer Terms, or your access to the Developer Tools, at any time, for any reason, with or without notice. <strong>Upon any termination or suspension of access, you may lose access to content, materials or other information that you provided to AirPage related to your Developer Product or through the Developer Tools.</strong> All sections of these Developer Terms that by their nature should survive termination shall survive termination, including without limitation Your Licenses to Us (2), Prohibited Actions (4.2), Confidentiality (5), Termination (6), Warranty Disclaimers (7), Limitation of Liability (8), and Indemnification (9).</p>
        <p><strong>7. &nbsp;&nbsp; &nbsp;Warranty Disclaimers</strong></p>
        <p><strong>To the fullest extent permitted by law, AirPage makes no warranties, either express or implied, about the Developer Tools. The Developer Tools are provided “as is.” AirPage also disclaims any warranties of merchantability, fitness for a particular purpose and non-infringement. No advice or information, whether oral or written, obtained by you from AirPage shall create any warranty. AirPage makes no warranty or representation that the Developer Tools will: (a) be timely, uninterrupted or error-free; (b) meet your requirements or expectations; or (c) be free from viruses or other harmful components. We make no representations that our Developer Tools are appropriate or available for use in all locations</strong>. Some places don't allow the disclaimers in this paragraph, so they may not apply to you.</p>
        <p><strong>8. &nbsp;&nbsp; &nbsp;Limitation of Liability</strong></p>
        <p><strong>To the fullest extent permitted by law, in no event will AirPage be liable with respect to any claims arising out of or related to the Developer Tools or these Developer Terms for: (a) any indirect, special, incidental, exemplary, punitive or consequential damages; (b) any loss of profits, revenue, goodwill or other intangible losses; (c) any damages related to your access to, use of or inability to access or use the Developer Tools, including without limitation interruption of use or cessation or modification of any aspect of the Developer Tools; (d) any damages related to loss or corruption of any content or data, including without limitation User Content and Developer Product data; (e) any User Content or other conduct or content of any user or third party using the Developer Tools, including without limitation defamatory, offensive or unlawful conduct or content; or (f) any Third Party Services or third party sites accessed via the Developer Tools. These limitations apply to any theory of liability, whether based on warranty, contract, tort, negligence, strict liability or any other legal theory, whether or not AirPage has been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed its essential purpose. To the fullest extent permitted by law, in no event shall the aggregate liability of AirPage for all claims arising out of or related to the Developer Tools and these Developer Terms exceed the greater of twenty U.S. dollars ($20) or the amounts paid by you to AirPage in the twelve (12) months immediately preceding the event that gave rise to such claim.</strong> Some places don't allow the types of limitations in this paragraph, so they may not apply to you.</p>
        <p><strong>9. &nbsp;&nbsp; &nbsp;Indemnification</strong></p>
        <p>To the fullest extent permitted by law, you agree to indemnify and hold harmless AirPage from and against all damages, losses and expenses of any kind (including without limitation reasonable attorneys' fees and costs) arising out of or related to: (a) your Developer Products; (b) any claims from Your Users; and (c) your violation of any law or regulation or the rights of any third party.</p>
        <p><strong>10. &nbsp;&nbsp; &nbsp;Modifications</strong></p>
        <p><strong>10.1. Modifications To Developer Tools.</strong> Our Developer Tools are constantly evolving as we refine, add and potentially remove features with the needs of our users and developers in mind, so please check in regularly to see the latest versions. You acknowledge that any such modifications may have an adverse effect on your Developer Product, including without limitation changing the manner in which it communicates with the Services, and you agree that AirPage shall not be liable to you in any way for such adverse effect. We’ll try to inform you of any changes with reasonable notice so you can adjust your use of the Developer Tools, but you agree that we’re not under any obligation to do so.</p>
        <p><strong>10.2. Modifications To Developer Terms.</strong> We may modify these Developer Terms periodically, and we’ll always post the most current version on our site. If a modification meaningfully reduces your rights, we'll notify you in accordance with the procedures set forth in our Terms of Service.</p>
        <p><strong>11. &nbsp;&nbsp; &nbsp;Additional Terms</strong></p>
        <p>These Developer Terms, together with our <a href="/terms-of-service">Terms of Service</a>, <a href="/privacy">Privacy Policy</a>, <a href="/acceptable-use-policy">Acceptable Use Policy</a> or <a href="/brand-guidelines">Brand Guidelines</a>., constitute the entire agreement between you and AirPage concerning the Developer Tools and supersede and replace any other prior or contemporaneous agreements, or terms and conditions applicable to the Developer Tools. These Developer Terms create no third party beneficiary rights. Our failure to enforce any provision of these Developer Terms is not a waiver of our right to do so later. If any provision of these Developer Terms is found unenforceable, the remaining provisions will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Developer Terms, and any such attempt will be void. We may assign our rights under these Developer Terms to any of our affiliates or subsidiaries, or to any successor in interest of any business associated with the Developer Tools.</p>
        </div>
    </LegalContainer>
  </MainLayout>
);
